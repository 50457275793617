import Highcharts from 'highcharts'

import { MetaAnalysisSpiderwebPlotBaseOptions } from 'pages/meta-analysis/MetaAnalysisSpiderwebPlotBaseOptions'
import { computeSpierwebPlotSeries } from 'pages/meta-analysis/series'

import {
  MetaAnalysisFile,
  MetaAnalysisSpiderwebPlotType,
} from 'shared/api/meta-analysis.api'

type RenderSpiderWebPlotChartProps = {
  chart: MetaAnalysisSpiderwebPlotType
  metaAnalysisFile: MetaAnalysisFile
  channels: string[]
  fileNameById: Record<string, string>
  width: number
  height: number
}

export const renderSpiderWebPlotChart = async ({
  chart,
  metaAnalysisFile,
  channels,
  fileNameById,
  width,
  height,
}: RenderSpiderWebPlotChartProps): Promise<string> => {
  const { series, xAxisCategories } = computeSpierwebPlotSeries(
    chart,
    metaAnalysisFile,
    fileNameById,
    channels,
  )
  return new Highcharts.Chart(document.createElement('div'), {
    ...MetaAnalysisSpiderwebPlotBaseOptions,
    chart: {
      ...MetaAnalysisSpiderwebPlotBaseOptions.chart,
      width,
      height,
    },
    xAxis: {
      ...MetaAnalysisSpiderwebPlotBaseOptions.xAxis,
      categories: xAxisCategories,
    },
    series,
    title: {
      ...MetaAnalysisSpiderwebPlotBaseOptions.title,
      text: chart.name,
    },
  }).getSVG()
}
