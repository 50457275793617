import { TooltipFormatterCallbackFunction } from 'highcharts'
import {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import styled, { useTheme } from 'styled-components'

import { DefaultErrorBoundary } from 'components/DefaultErrorBoundary'
import { Modal } from 'components/Modal'
import { Chart } from 'components/graphs/Chart'

import { MetaAnalysisSpiderwebPlotType } from 'shared/api/meta-analysis.api'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { useAppDispatch, useAppSelector } from 'shared/store'
import { downloadText, useSize } from 'shared/utils/utils'

import { MetaAnalysisChartContainer } from './MetaAnalysisChartContainer'
import { MetaAnalysisSpiderwebPlotBaseOptions } from './MetaAnalysisSpiderwebPlotBaseOptions'
import { MetaAnalysisSpiderwebPlotOptions } from './MetaAnalysisSpiderwebPlotOptions'
import { computeSpierwebPlotSeries } from './series'
import { updateMetaAnalysisChart } from './store/meta-analysis.history.slice'
import {
  selectMetaAnalysisChannels,
  selectMetaAnalysisFcsFileNameById,
  selectMetaAnalysisFile,
  selectMetaAnalysisName,
} from './store/selectors'
import { useMetaAnalysisCsvMetadata } from './useMetaAnalysisCsvMetadata'

type MetaAnalysisSpiderwebPlotProps = {
  chart: MetaAnalysisSpiderwebPlotType
  isExpanded?: boolean
  className?: string
  onCloseExpand?: () => void
}

export const MetaAnalysisSpiderwebPlot = ({
  chart,
  isExpanded,
  className,
  onCloseExpand,
}: MetaAnalysisSpiderwebPlotProps): JSX.Element => {
  const dispatch = useAppDispatch()

  const [ref, { width, height }] = useSize<HTMLDivElement>({ debounce: 250 })
  const innerPlotRef = useRef<InnerPlotInstance>(null)

  const [shouldDisplayChartOptions, setShouldDisplayChartOptions] =
    useState(false)
  const [shouldShowExpandedSelf, setShouldShowExpandedSelf] = useState(false)

  const handleChangeOptions = useEventCallback(() => {
    setShouldDisplayChartOptions(true)
  })

  const handleCancel = useEventCallback(() => {
    setShouldDisplayChartOptions(false)
  })

  const handleApplyOptions = useEventCallback(
    (fileIds: string[], cluster: string, channels: string[]) => {
      dispatch(
        updateMetaAnalysisChart({
          id: chart.id,
          selectedFileIds: fileIds,
          selectedChannels: channels,
          selectedCluster: cluster,
        }),
      )
      setShouldDisplayChartOptions(false)
    },
  )

  const handleDownload = useEventCallback(async () => {
    innerPlotRef.current?.downloadSeries()
  })

  return (
    <MetaAnalysisChartContainer
      containerRef={ref}
      chart={chart}
      isExpanded={isExpanded}
      className={className}
      onChangeOptions={handleChangeOptions}
      onExpand={() => setShouldShowExpandedSelf(true)}
      onCloseExpand={onCloseExpand}
      onDownload={handleDownload}
    >
      <DefaultErrorBoundary>
        <InnerPlot
          chart={chart}
          width={width}
          height={height}
          ref={innerPlotRef}
        />
      </DefaultErrorBoundary>
      {shouldDisplayChartOptions && (
        <StyledMetaAnalysisSpiderwebPlotOptions
          mode="edit"
          initialSelectedFileIds={chart.selectedFileIds}
          initialSelectedCluster={chart.selectedCluster}
          initialSelectedChannels={chart.selectedChannels}
          onCancel={handleCancel}
          onFinish={handleApplyOptions}
        />
      )}
      {shouldShowExpandedSelf && (
        <Modal open onClose={() => setShouldShowExpandedSelf(false)}>
          <ExpandedMetaAnalysisSpiderwebPlot
            chart={chart}
            onCloseExpand={() => setShouldShowExpandedSelf(false)}
          />
        </Modal>
      )}
    </MetaAnalysisChartContainer>
  )
}

type InnerPlotProps = Pick<MetaAnalysisSpiderwebPlotProps, 'chart'> & {
  width: number
  height: number
}

type InnerPlotInstance = {
  downloadSeries: () => void
}

const InnerPlot = forwardRef<InnerPlotInstance, InnerPlotProps>(
  ({ chart, width, height }, ref) => {
    const theme = useTheme()

    const metaAnalysisName = useAppSelector(selectMetaAnalysisName)
    const metaAnalysisFile = useAppSelector(selectMetaAnalysisFile)
    const fileNameById = useAppSelector(selectMetaAnalysisFcsFileNameById)
    const channels = useAppSelector(selectMetaAnalysisChannels)

    const { series, xAxisCategories } = useMemo(
      () =>
        computeSpierwebPlotSeries(
          chart,
          metaAnalysisFile,
          fileNameById,
          channels,
        ),
      [channels, chart, fileNameById, metaAnalysisFile],
    )

    const createCsvMetadata = useMetaAnalysisCsvMetadata()
    const downloadSeries = useEventCallback(async () => {
      const headerRow = 'file,channel,cluster,mode,intensity'
      const dataRows = chart.selectedFileIds.flatMap((_, fileIndex) => {
        return chart.selectedChannels.map((_, channelIndex) => {
          return `${series[fileIndex].name},${
            chart.selectedChannels[channelIndex]
          },${chart.selectedCluster},${series[fileIndex].data![channelIndex]}`
        })
      })

      const csv =
        (await createCsvMetadata()) + [headerRow, ...dataRows].join('\n')
      downloadText(csv, `${metaAnalysisName} - ${chart.name}.csv`)
    })
    useImperativeHandle(ref, () => ({ downloadSeries }), [downloadSeries])

    const options: Highcharts.Options = useMemo(() => {
      const formatTooltip: TooltipFormatterCallbackFunction = function (this) {
        return renderToStaticMarkup(
          <div
            style={{
              padding: 8,
              fontSize: 13,
              minWidth: 120,
              background: theme.colors.white,
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <span style={{ fontFamily: theme.font.style.bold }}>
                {this.series.name}
              </span>
              <span
                style={{
                  color: this.point.color as string,
                  marginRight: 4,
                  fontSize: 14,
                }}
              >
                ⬤
              </span>
            </div>
            <div style={{ fontSize: 12, color: theme.colors.primaryDark[70] }}>
              {chart.selectedCluster}
            </div>
            <div
              style={{
                fontSize: 12,
                marginTop: 6,
                color: theme.colors.primaryDark[70],
              }}
            >
              {`Intensity for ${this.point.category} :${this.y?.toFixed(2)}`}
            </div>
          </div>,
        )
      }

      return {
        ...MetaAnalysisSpiderwebPlotBaseOptions,
        chart: {
          ...MetaAnalysisSpiderwebPlotBaseOptions.chart,
          width,
          height,
        },
        xAxis: {
          ...MetaAnalysisSpiderwebPlotBaseOptions.xAxis,
          categories: xAxisCategories,
        },
        series,
        tooltip: {
          ...MetaAnalysisSpiderwebPlotBaseOptions.tooltip,
          formatter: formatTooltip,
        },
      }
    }, [
      chart.selectedCluster,
      height,
      series,
      theme.colors.primaryDark,
      theme.colors.white,
      theme.font.style.bold,
      width,
      xAxisCategories,
    ])

    return <Chart options={options} />
  },
)

const StyledMetaAnalysisSpiderwebPlotOptions = styled(
  MetaAnalysisSpiderwebPlotOptions,
)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`

const ExpandedMetaAnalysisSpiderwebPlot = styled(
  MetaAnalysisSpiderwebPlot,
).attrs({
  isExpanded: true,
})`
  width: 90vw;
  height: 90vh;
`
