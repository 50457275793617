import { FcsFile } from 'shared/api/files.api'
import {
  MetaAnalysisChart,
  MetaAnalysisColors,
  MetaAnalysisFile,
  MetaAnalysisGlobalVizFile,
  MetaAnalysisVolcanoPlotFile,
} from 'shared/api/meta-analysis.api'
import { handleError } from 'shared/utils/errorHandler'

import { AppTheme } from 'Theme'

import { renderBoxPlotChart } from './renderBoxPlotChart'
import { renderDimensionalityReductionChart } from './renderDimensionalityReductionChart'
import { renderFrequencyChart } from './renderFrequencyChart'
import { renderGlobalHeatMapChart } from './renderGlobalHeatMapChart'
import { renderHistogram } from './renderHistogram'
import { renderScatterPlot } from './renderScatterPlot'
import { renderSpiderWebPlotChart } from './renderSpiderWebPlotChart'
import { renderVolcanoPlot } from './renderVolcanoPlot'

type RenderMetaAnalysisChartProps = {
  chart: MetaAnalysisChart
  metaAnalysisFile: MetaAnalysisFile
  channels: string[]
  fileNameById: Record<string, string>
  fcsFiles: FcsFile[]
  globalVizFile: MetaAnalysisGlobalVizFile | undefined
  dimensionalityReductionMethod: string | null
  volcanoPlotFile: MetaAnalysisVolcanoPlotFile
  theme: AppTheme
  width: number
  height: number
  colors: MetaAnalysisColors
}

export const renderMetaAnalysisChart = ({
  chart,
  metaAnalysisFile,
  channels,
  fileNameById,
  fcsFiles,
  globalVizFile,
  dimensionalityReductionMethod,
  volcanoPlotFile,
  theme,
  width,
  height,
  colors,
}: RenderMetaAnalysisChartProps): Promise<string> => {
  switch (chart.type) {
    case 'box-plot':
      return renderBoxPlotChart({
        chart,
        metaAnalysisFile,
        channels,
        fileNameById,
        width,
        height,
      })
    case 'frequency-chart':
      return renderFrequencyChart({
        chart,
        metaAnalysisFile,
        colors,
        channels,
        fileNameById,
        width,
        height,
      })
    case 'spiderweb-plot':
      return renderSpiderWebPlotChart({
        chart,
        metaAnalysisFile,
        channels,
        fileNameById,
        width,
        height,
      })
    case 'global-heat-map':
      return renderGlobalHeatMapChart({
        chart,
        metaAnalysisFile,
        colors,
        fileNameById,
        width,
        height,
      })
    case 'dimensionality-reduction':
      if (dimensionalityReductionMethod !== null) {
        if (globalVizFile) {
          return renderDimensionalityReductionChart({
            chart,
            metaAnalysisFile,
            fcsFiles,
            globalVizFile: globalVizFile,
            dimensionalityReductionMethod,
            width,
            height,
          })
        } else {
          throw handleError(new Error('Global viz file missing'))
        }
      } else {
        throw handleError(
          new Error('Current meta-analysis has no dimensionality reduction'),
        )
      }
    case 'volcano-plot':
      return renderVolcanoPlot({ chart, volcanoPlotFile, theme, width, height })
    case 'scatter-plot':
      if (dimensionalityReductionMethod !== null) {
        if (globalVizFile) {
          return renderScatterPlot({
            chart,
            metaAnalysisFile,
            files: fcsFiles,
            globalVizFile: globalVizFile,
            dimensionalityReductionMethod,
            width,
            height,
          })
        } else {
          throw handleError(new Error('Global viz file missing'))
        }
      } else {
        throw handleError(
          new Error('Current meta-analysis has no dimensionality reduction'),
        )
      }
    case 'histogram':
      if (dimensionalityReductionMethod !== null) {
        if (globalVizFile) {
          return renderHistogram({
            chart,
            metaAnalysisFile,
            files: fcsFiles,
            globalVizFile: globalVizFile,
            dimensionalityReductionMethod,
            width,
            height,
          })
        } else {
          throw handleError(new Error('Global viz file missing'))
        }
      } else {
        throw handleError(
          new Error('Current meta-analysis has no dimensionality reduction'),
        )
      }
  }
}
