export function sort<T>(
  elements: T[],
  order: 'asc' | 'desc',
  selector: ((element: T) => string) | ((element: T) => number),
): T[] {
  return [...elements].sort((a, b) => {
    const aValue = selector(a)
    const bValue = selector(b)

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return order === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue)
    }

    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return order === 'asc' ? aValue - bValue : bValue - aValue
    }

    throw new Error('Cannot compare elements with different types')
  })
}

export type SortingOrder = 'ascending' | 'descending'

export const getBounds = (iterable: Iterable<number>): [number, number] => {
  let min = Infinity
  let max = -Infinity

  for (const value of iterable) {
    min = Math.min(min, value)
    max = Math.max(max, value)
  }

  return [min, max]
}
