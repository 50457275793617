import Highcharts from 'highcharts'

import { FcsFile } from 'shared/api/files.api'
import {
  MetaAnalysisFile,
  MetaAnalysisGlobalVizFile,
  MetaAnalysisHistogramType,
} from 'shared/api/meta-analysis.api'
import { metaAnalysisWorker } from 'shared/worker'

type RenderHistogramProps = {
  chart: MetaAnalysisHistogramType
  metaAnalysisFile: MetaAnalysisFile
  files: FcsFile[]
  globalVizFile: MetaAnalysisGlobalVizFile
  dimensionalityReductionMethod: string
  width: number
  height: number
}

export const renderHistogram = async ({
  chart,
  metaAnalysisFile,
  globalVizFile,
  files,
  width,
  height,
}: RenderHistogramProps): Promise<string> => {
  const { series } = await metaAnalysisWorker.computeHistogramSeries({
    chart,
    metaAnalysisFile,
    globalVizFile,
    files,
  })

  const highchartsChart = new Highcharts.Chart(document.createElement('div'), {
    chart: {
      type: 'column',
      width,
      height,
    },
    boost: {
      seriesThreshold: 1,
    },
    xAxis: {
      title: {
        text: chart.channel,
      },
    },
    yAxis: {
      title: {
        text: undefined,
      },
    },
    title: {
      text: chart.name,
    },
    series: Object.values(series).flat(),
  })

  return highchartsChart.getSVG()
}
