import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { Layout, LayoutElement } from 'components/chart-grid'
import { ChartGrid } from 'components/chart-grid/ChartGrid'

import { ADD_CHART_PLACEHOLDER_ID } from 'shared/constants'
import { useAppDispatch, useAppSelector } from 'shared/store'

import { AddMetaAnalysisChart } from './AddMetaAnalysisChart'
import { MetaAnalysisBoxPlot } from './MetaAnalysisBoxPlot'
import { MetaAnalysisDimensionalityReductionChart } from './MetaAnalysisDimensionalityReductionChart'
import { MetaAnalysisFrequencyChart } from './MetaAnalysisFrequencyChart'
import { MetaAnalysisGlobalHeatMapChart } from './MetaAnalysisGlobalHeatMapChart'
import { MetaAnalysisHistogram } from './MetaAnalysisHistogram'
import { MetaAnalysisScatterPlot } from './MetaAnalysisScatterPlot'
import { MetaAnalysisSpiderwebPlot } from './MetaAnalysisSpiderwebPlot'
import { MetaAnalysisVolcanoPlot } from './MetaAnalysisVolcanoPlot'
import { changeSpecificLayout } from './store/meta-analysis.history.slice'
import { updateSpecificLayout } from './store/meta-analysis.slice'
import {
  selectMetaAnalysisCharts,
  selectMetaAnalysisColors,
  selectMetaAnalysisLayout,
} from './store/selectors'

export const MetaAnalysisLayout = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const layout = useAppSelector(selectMetaAnalysisLayout)
  const charts = useAppSelector(selectMetaAnalysisCharts)
  const colors = useAppSelector(selectMetaAnalysisColors)

  const handleChangeSpecificLayout = useCallback(
    (key: keyof Layout, layout: LayoutElement) => {
      dispatch(
        changeSpecificLayout({
          layoutKey: key,
          layout,
        }),
      )
    },
    [dispatch],
  )

  const handleUpdateSpecificLayout = useCallback(
    (key: keyof Layout, layout: LayoutElement) => {
      dispatch(
        updateSpecificLayout({
          layoutKey: key,
          layout,
        }),
      )
    },
    [dispatch],
  )

  const chartElements = useMemo(() => {
    return [
      ...charts.map(chart => {
        if (chart.type === 'global-heat-map') {
          return (
            <MetaAnalysisGlobalHeatMapChart
              key={chart.id}
              chart={chart}
              colors={colors}
            />
          )
        }

        if (chart.type === 'dimensionality-reduction') {
          return (
            <MetaAnalysisDimensionalityReductionChart
              key={chart.id}
              chart={chart}
            />
          )
        }

        if (chart.type === 'box-plot') {
          return <MetaAnalysisBoxPlot key={chart.id} chart={chart} />
        }

        if (chart.type === 'frequency-chart') {
          return (
            <MetaAnalysisFrequencyChart
              key={chart.id}
              chart={chart}
              colors={colors}
            />
          )
        }

        if (chart.type === 'spiderweb-plot') {
          return <MetaAnalysisSpiderwebPlot key={chart.id} chart={chart} />
        }

        if (chart.type === 'volcano-plot') {
          return <MetaAnalysisVolcanoPlot key={chart.id} chart={chart} />
        }

        if (chart.type === 'scatter-plot') {
          return <MetaAnalysisScatterPlot key={chart.id} chart={chart} />
        }

        if (chart.type === 'histogram') {
          return <MetaAnalysisHistogram key={chart.id} chart={chart} />
        }

        throw new Error('Unknown chart type')
      }),
      <AddMetaAnalysisChart key={ADD_CHART_PLACEHOLDER_ID} />,
    ]
  }, [charts, colors])

  return (
    <MetaAnalysisLayoutRoot>
      <ChartGrid
        layout={layout}
        onChangeSpecificLayout={handleChangeSpecificLayout}
        onUpdateSpecificLayout={handleUpdateSpecificLayout}
      >
        {chartElements}
      </ChartGrid>
    </MetaAnalysisLayoutRoot>
  )
}

const MetaAnalysisLayoutRoot = styled.div`
  overflow: hidden;
`
